import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
// import { Link } from 'gatsby'
import MainSlider from 'components/MainSlider'


class MainCopy extends Component {

  render() {
    // const { images } = this.state
    return (
      
    
    <Container fluid className="main-image">
        <div className="content">
            <Row className="d-flex align-items-center">
                <Col sm={12} xl={6}>
                    <MainSlider />
                </Col>
                <Col sm={12} xl={6} className="main-copy">

                    {/* <Col sm={{ size: 10, offset: 1 }}> */}
                    <h3>Since 1987 Michael Rose has produced over 75 musicals and plays.</h3>

                    <p className="main-copy">Productions include <em>Chitty Chitty Bang Bang</em> at the London Palladium and on Broadway, UK Tour, Dublin and Singapore; <em>A Steady Rain</em> starring Daniel Craig and Hugh Jackman at the Schoenfeld Theatre, Broadway; <em>Footloose</em> at the Novello Theatre, London; Duke Ellington&rsquo;s<em> Sophisticated Ladies</em> at the Gielgud Theatre; Pam Gem&rsquo;s <em>Marlene</em> at The Lyric; <em>Sweet Charity</em> at the Victoria Palace; <em>Chariots of Fire On Stage</em> at the Gielgud Theatre, <em>Aladdin</em> starring Lily Savage by kind permission of Paul O&rsquo;Grady at the purpose built Theatre at The O2; <em>Once </em>at the Phoenix Theatre; <em>Strangers On A Train</em> at the Gielgud Theatre starring Laurence Fox and Jack Huston.</p>
                     <p className="main-copy">Irving Berlin’s White Christmas the musical, ELF the musical and BIG the musical all at the Dominion Theatre and Sleepless the musical at the Troubadour Theatre Wembley Park London.</p>



                    {/*<div>
                       <Link to="/about-us/" className="btn">about us</Link>
                        <Link to="/productions/" className="btn">Productions</Link>
                    </div>*/}
                </Col>

            </Row>
        </div>
        
    </Container>
    
    )
  }
}

export default MainCopy
