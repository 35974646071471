import React, { Component } from 'react'
import {graphql} from "gatsby";
// import {fluidImage, fluidImageTablet, fluidImageMobile} from 'components/Image'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
import MainBlock from "../components/MainBlock"

class IndexPage extends Component {

	
	render() {
		const props = this.props;
		return(
			<Layout>
				<SEO title="Home" description="Michael Rose" />
				<Hero 
				headerImage={props.data.headerIndexImage.childImageSharp.fluid}
				headerImageTablet={props.data.headerIndexImageTablet.childImageSharp.fluid}
				headerImageMobile={props.data.headerIndexImageMobile.childImageSharp.fluid}
				display="none"
				displayLogo="none"/>
				<MainBlock />
			</Layout>
		)
	}
}
export default IndexPage


export const IndexPageQuery = graphql`
query {
    headerIndexImage: file(relativePath: { eq: "header/1920-header.jpg" }) {
		childImageSharp {
			fluid(maxWidth: 1920) {
				...GatsbyImageSharpFluid_noBase64
			}
		}
	}
	headerIndexImageTablet: file(relativePath: { eq: "header/768-Header.jpg" }) {
		childImageSharp {
			fluid(maxWidth: 768) {
				...GatsbyImageSharpFluid_noBase64
			}
		}
    }
    headerIndexImageMobile: file(relativePath: { eq: "header/576-Header.jpg" }) {
		childImageSharp {
			fluid(maxWidth: 576) {
				...GatsbyImageSharpFluid_noBase64
			}
		}
    }
}`