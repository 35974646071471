import React, { Component } from "react";
import Slider from "react-slick";

import image1 from '../images/sliders/Images-1.png'
import image2 from '../images/sliders/Images-2.png'
import image3 from '../images/sliders/Images-3.png'


class MainSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1500,
      slidesToShow: 3,
      slidesToScroll: 1,
      fade: true,
      arrows: false,
      autoplay: true
    };
    return (
      <div>
            <Slider {...settings}>
                <div>
                    <img src={image1} alt="M"></img>
                </div>
                <div>
                    <img src={image2} alt="M"></img>
                </div>
                <div>
                    <img src={image3} alt="M"></img>
                </div>
            </Slider>
      </div>
    );
  }
}
// export const SliderQuery = graphql`
// query {
//     sliderImage1: file(relativePath: { eq: "sliders/Images-1.png" }) {
//     	...fluidImage
// 	}
// 	sliderImage2: file(relativePath: { eq: "sliders/Images-2.png" }) {
//         ...fluidImage
//     }
//     sliderImage13: file(relativePath: { eq: "sliders/Images-3.png" }) {
//         ...fluidImage
//     }
// }`

export default MainSlider